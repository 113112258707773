import { GlobalOrder } from '../../../models'

export const mapStateToProps = (
    {
        activeGlobalOrdersList: activeGlobalOrders,
        currentGlobalOrder,
        currentLocale,
        currentUser,
        switchTo,
        initRequest,
        locales,
        nextGlobalOrdersList: nextGlobalOrders,
        currentDate,
        session: { homedeliveryWarningSeen, sideMenuVisible },
    },
    { match, eventEmitter }
) => {
    let globalorder =
        currentGlobalOrder &&
        currentGlobalOrder.order_start &&
        new GlobalOrder(currentGlobalOrder)

    const category = (match.params && match.params.category) || 'promotions'
    const family = (match.params && match.params.family) || 'promotions'

    /* scrollKey will be the first non-falsy among family / category / 'top' */
    // const scrollKey =
    //     (match.params && match.params.family) ||
    //     (match.params && match.params.category) ||
    //     'top'

    const locale = typeof currentLocale.code !== 'undefined' ? currentLocale : undefined

    const isLocaleHomeDelivery = locale && locale.code === 'DOM'

    const homedeliveryLocale = Object.values(locales).find(
        l => l.code === 'DOM' && !l.private
    )

    const showHomeDeliveryWarning = false
    // isLocaleHomeDelivery &&
    // !homedeliveryWarningSeen &&
    // ((!!currentUser.loggedin && !currentUser.has_valid_delivery_address) ||
    //     !currentUser.loggedin)

    return {
        locale,
        eventEmitter,
        currentGlobalOrder:
            typeof currentGlobalOrder._id !== 'undefined'
                ? currentGlobalOrder
                : undefined,
        activeGlobalOrders,
        nextGlobalOrders,
        localesInitialized: initRequest && initRequest.localesReady,
        initialized: !!initRequest && !!initRequest.ready,
        error: !!initRequest.error,
        hasMultipleActiveGlobalOrders: activeGlobalOrders.length > 1,
        isActiveCurrentGlobalOrder:
            !!globalorder && globalorder.isActive(currentDate),
        isLocaleFull: !!globalorder && globalorder.isFull(),
        isLocaleHomeDelivery,
        showHomeDeliveryWarning,
        homedeliveryLocale,
        currentUser,
        switchTo,
        // scrollKey,
        category,
        family,
        /**
         * private locales are invisible to non-members, so we need to show the
         * AllLocalesFullView even if private locales are not yet full...
         * activeGlobalOrders[0].all_locales_full will be true even if some private locales
         * are still open
         */
        allLocalesFull:
            activeGlobalOrders.length === 1 &&
            activeGlobalOrders[0].all_locales_full &&
            typeof currentLocale.code !== 'undefined' &&
            !currentLocale.private,
        sideMenuVisible,
    }
}
